import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { catchError, delay, finalize, of, tap } from 'rxjs';
import { LoginService } from './service/login.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  hidePassword = true;
  isLoading = false;
  errorMessage: string = '';

  destroyRef = inject(DestroyRef);

  loginForm = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
  });

  constructor(
    private readonly fb: NonNullableFormBuilder,
    private readonly loginService: LoginService,
    private readonly router: Router,
  ) {}

  doLogin() {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }

    const { email, password } = this.loginForm.getRawValue();
    this.isLoading = true;

    this.loginService
      .login(email, password)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => {
          this.router.navigate(['/merchants']);
        }),
        catchError((error) => {
          this.errorMessage = 'Something went wrong, please try again';
          return of(error);
        }),
        finalize(() => {
          this.isLoading = false;
        }),
        delay(5000),
        tap(() => {
          this.errorMessage = '';
        }),
      )
      .subscribe();
  }
}
