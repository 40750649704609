<div class="d-flex flex-column justify-content-center align-items-center min-vh-100 px-3">
  <img class="img-fluid" src="assets/images/shop_icon_dark.png" alt="Shop & Win Logo" />
  <h1 class="mt-5 mb-4">Login</h1>
  <form [formGroup]="loginForm" class="d-grid w-100">
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input type="email" matInput formControlName="email" />
      @if (loginForm.controls.email.hasError("required")) {
        <mat-error>Email is <strong>required</strong></mat-error>
      }
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="password" />
      <button
        matSuffix
        mat-icon-button
        aria-label="toggle visibility"
        (click)="hidePassword = !hidePassword"
      >
        <mat-icon>{{ hidePassword ? "visibility_off" : "visibility" }}</mat-icon>
      </button>
      @if (loginForm.controls.email.hasError("required")) {
        <mat-error>Password is <strong>required</strong></mat-error>
      }
    </mat-form-field>

    <button
      mat-raised-button
      class="py-4"
      (click)="doLogin()"
      color="primary"
      [disabled]="isLoading"
    >
      @if (isLoading) {
        <mat-spinner [diameter]="20"></mat-spinner>
      } @else {
        Login
      }
    </button>
  </form>
  <div class="d-flex justify-content-center mt-5">
    <mat-error>{{ errorMessage }}</mat-error>
  </div>
</div>
